<script setup lang="ts">
const { t } = useI18n();

const links = computed(() => ([
	{
		label: t('button.imprint'),
		to: t('link.imprint'),
	},
	{
		label: t('button.privacyPolicy'),
		to: t('link.privacyPolicy'),
	},
	{
		label: t('button.tos'),
		to: t('link.tos'),
	},

]));
</script>

<template>
	<div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-8 lg:py-4 lg:flex lg:items-center lg:justify-between lg:gap-x-3">
		<div class="text-sm flex gap-4 text-gray-400">
			<NuxtLink
				v-for="link in links"
				:key="link.to"
				:to="link.to"
				class="hover:text-gray-600"
				target="_blank"
			>
				{{ link.label }}
			</NuxtLink>
		</div>
	</div>
</template>
